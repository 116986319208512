import React from "react";

function LandingPage() {
  return (
    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto">
              <h1 className="mb-5">You have a soundtrack for your life, share it on Volley Music... in real time ✌️</h1>
              <a className="badge-link" href="https://apps.apple.com/us/app/volley-music/id1402819460">
                <img src="img/app-store-badge.svg" alt="" />
              </a>
            </div>
          </div>
          <div className="col-lg-5 my-auto">
              
            <div className="device-wrapper" style={{marginLeft: '22px'}}>
              <div className="device" data-device="iPhoneX" data-orientation="portrait" data-color="black">
                <div className="screen">
                  <img src="img/home.png" className="img-fluid" alt="" />
                </div>
                <div className="button" />
              </div> 
            </div>
          </div>
        </div>
      </div>
    </header>
    )
}

export default LandingPage