import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Footer from './components/Footer'
import Header from './components/Header'
import Social from './components/Social'
import LandingPage from './views/Landing'
import InvitePage from './views/Invite'
import NotFound from './views/NotFound'

import './App.scss'

export default function App() {
    return (
      <>
      <Header />
        <Router>
            <Switch>
                <Route exact path="/">
                    <LandingPage />
                </Route>
                <Route exact path="/invite/:username">
                    <InvitePage />
                </Route>
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        </Router>
        <Social />
        <Footer />
        </>
    )
}