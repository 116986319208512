import React, {useState, useEffect} from "react";
function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }
    
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

function Header() {
  const size = useWindowSize();
  const fullSizeHeader = 'navbar navbar-expand-lg navbar-light fixed-top';
  const fullSizeLogo = 'light';
  const shrunkenHeader = 'navbar navbar-expand-lg navbar-light fixed-top navbar-shrink';
  const shrunkenLogo = 'dark';
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  let logoClass = fullSizeLogo;
  if (scrollTop > 75) {
    logoClass = shrunkenLogo;
  }

  if (size.width < 1000) {
    logoClass = shrunkenLogo;
  }
  return (
    <nav className={scrollTop < 75 ? fullSizeHeader : shrunkenHeader} id="mainNav">
      <div className="container">
        <a className="navbar-brand js-scroll-trigger" href="#page-top">
          <div id="main-img" className={logoClass} />
        </a>
      </div>
    </nav>
  )
}

export default Header;