import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHeart } from '@fortawesome/free-solid-svg-icons'
import {faInstagram} from '@fortawesome/free-brands-svg-icons'
function Social() {
    return (
        <section className="contact bg-primary" id="contact">
        <div className="container">
          <h2>We  <FontAwesomeIcon icon={faHeart} color="#dd4b39"/>  new friends!</h2>
          <ul className="list-inline list-social">
            <li className="list-inline-item social-instagram">
              <a href="https://www.instagram.com/volleymusic/">
                <FontAwesomeIcon icon={faInstagram}  size='sm' />
              </a>
            </li>
          </ul>
        </div>
      </section>
    )
}

export default Social